import Tagify from "@yaireo/tagify";

var tagify;

$(document).on("turbolinks:load", () => {
  let input = document.querySelector("#report_to_do_responsibles");

  if (input) {
    tagify = new Tagify(input, {
      pattern:
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      whitelist: []
    });

    tagify.on("focus", onFocus);
  }

  if (document.querySelector("#report_to_do_done_checkbox")) {
    $(".checkbox-container.report-to-do").on("click", toggleCheckBoxValue);
  }

  if (document.querySelector("#report_to_do_done_checkbox_on_show")) {
    $(".checkbox-container.report-to-do").on("click", submitShowForm);
  }
});

function onFocus(e) {
  var value = e.detail.value;

  if (value.length < 3) {
    return false;
  }

  tagify.whitelist = null;

  controller && controller.abort();
  controller = new AbortController();

  tagify.loading(true).dropdown.hide();

  let split_current_url = window.location.href.split("/");
  let search_url = `${split_current_url[0]}//${split_current_url[2]}/${split_current_url[3]}/${split_current_url[4]}/${split_current_url[5]}/${split_current_url[6]}/report_to_dos/list_external_users?query=${value}`;

  fetch(search_url, {
    signal: controller.signal,
  })
    .then((RES) => RES.json())
    .then(function (newWhitelist) {
      tagify.whitelist = newWhitelist;
      tagify.loading(false).dropdown.show(value);
    });
}

function toggleCheckBoxValue(e, checkbox_id = null) {
  e.preventDefault();
  let checkbox = checkbox_id
    ? document.querySelector(checkbox_id)
    : document.querySelector("#report_to_do_done_checkbox");
  if (checkbox.checked && checkbox.value === "1") {
    checkbox.checked = false;
    checkbox.value = "0";
  } else {
    checkbox.checked = true;
    checkbox.value = "1";
  }
}

function submitShowForm(e) {
  if (
    document
      .querySelector("#report_to_do_done_checkbox_on_show")
      .getAttribute("disabled") === "disabled"
  ) {
    return false;
  }
  toggleCheckBoxValue(e, "#report_to_do_done_checkbox_on_show");
  document.querySelector("form.edit_report_to_do").submit();
}
